import { FRONTEND, BACKEND, MOBILE, AI, DATASCIENCE } from '../Assets';
export const developerData = {
  frontend: {
    title: '프론트엔드 개발자',
    image: FRONTEND,
    description: `🤔 프론트엔드 개발자가 뭐에요?

    프론트엔드(Front-End) 개발자는 사용자가 보는 웹 페이지의 외견을 개발하는 직무에요. 사용자와 직접적으로 닿는 앞부분을 개발한다는 의미죠. 네이버 페이지를 접속하면 제일 먼저 보이는 이미지, 글자들(텍스트)처럼 단순히 보이는 것뿐만 아니라 검색창, 로그인 버튼처럼 사용자와 상호작용도 개발합니다. 프론트엔드 개발자는 사용자가 원하는 기능이 있도록 웹 페이지 구조를 설정해야 합니다. 또한, 모바일, 컴퓨터처럼 다양한 기기에서 접속했을 때의 비율을 고려하여 최적화하는 일도 해요. 시각적으로 보이는 웹 화면을 개발하다 보니 디자이너와의 협업도 중요하죠.
    
    🥺 어떤 사람이 프론트 엔드 개발에 어울릴까요?
    
    자신이 만든 결과물을 시각적으로 바로 확인하는 것을 좋아하는 분에게 어울리는 직무에요. 작성한 코드를 화면에서 바로 볼 수 있고, 사용자와 바로 맞닿는 만큼 피드백도 빠르게 받아볼 수 있어요.`,
  },
  backend: {
    title: '백엔드 개발자',
    image: BACKEND,
    description: `🤔 백엔드 개발자가 뭐에요?

    백엔드(Back-End) 개발자는 프론트엔드에서 만들어진 기능이 제대로 작동할 수 있도록 데이터를 처리 및 가공, 전달하는 서버를 개발해요. 사용자와 맞닿는 앞부분을 개발하는 프론트엔드와 반대로, 사용자에게 보이지 않는 뒷부분을 개발하는 업무를 의미하죠. 예를 들어 네이버에서 접속했을 때, 오늘의 뉴스와 광고 등의 정보를 프론트엔드에 전달하는 업무를 개발합니다. 단순히 정보를 전달하는 것뿐 아니라 여러 명이 동시 접속했을 때 어떻게 대처할지도 고민해봐야 해요. 티켓팅 때 대기 O명이라는 문구를 보신적 있죠? 이 또한 백엔드 개발자가 다뤄야하는 업무에요.
    
    🥺 어떤 사람이 백엔드 개발에 어울릴까요?
    
    복잡하고 고도화된 문제를 진득하게 해결하는 걸 좋아하는 분에게 어울리는 직무에요. 서비스가 점점 발전하며 여러 기능이 많아질수록 처리해야할 데이터의 종류와 양이 기하급수적으로 증가해요. 따라서, 복잡한 서비스를 체계적으로 풀어 설계하고, 데이터를 꼼꼼히 다루는 능력이 중요해요.`,
  },
  ai: {
    title: '인공지능 개발자',
    image: AI,
    description: `🤔 AI 개발자가 뭐에요?

    AI 개발자는 기계가 데이터를 수집하고, 처리 및 분석하도록 모델을 개발하여 인공지능 시스템을 만드는 직무에요. 최근에 많이 사용되는 ChatGPT 또한 AI 개발자가 만든 결과물이죠. AI 모델을 통해 유튜브의 알고리즘, 무신사의 추천 서비스까지 사용자의 맞춤 서비스를 만들어낼 수  있어요. 워낙 빠르게 발전하고 파생되는 분야인 만큼 끊임없는 학습과 적용이 중요한 분야에요. 따라서, 연구 개발(R&D)의 성격을 띄는 업무를 수행하죠.
    
    🤔 어떤 사람이 AI 개발에 어울릴까요?
    
    미래지향적인 모습을 꿈꾸고, AI 기술을 기반으로 한 문제 해결에 관심이 많은 분에게 어울리는 직무에요. 인공지능이 사람과 상생하며 문제를 해결할 수 있도록 기계와 소통하고 연구해야 해요. AI 분야는 현재 급속도로 성장하고 있는 만큼 전망에 있어 밝다고 볼 수 있어요.`,
  },
  datascience: {
    title: '데이터분석가',
    image: DATASCIENCE,
    description: `🤔 데이터 분석가가 뭐에요?

    데이터 분석가는 객관적인 의사결정을 돕기 위해 데이터를 분석하여 데이터에 담긴 의미를 찾는 직무에요. 데이터 분석가는 데이터 수집, 정제, 시각화, 통계 분석, AI 모델 개발 등의 역할을 수행해요. AI 개발자와 배우는 분야가 비슷하지만, 데이터에 대한 분석에 더욱 초점이 맞춰져 있어요. 데이터 분석을 통한 기업의 수익 증대, 고객 만족도 향상, 비즈니스 프로세스 개선들에 기여하죠. 데이터 분석가는 100% 개발 직무라고 보기 어렵지만, 그에 맞는 개발 언어와 툴을 사용한다는 점에서 개발 직무로 볼 수도 있어요.
    
    🤔 어떤 사람이 데이터 분석에 어울릴까요?
    
    호기심이 많고, 수리적/논리적 사고를 바탕으로 다른 사람들이 찾지 못한 관점을 찾아내는 것을 좋아하는 분에게 어울리는 직무에요. 현대에 방대한 데이터 속에서 숨겨진 의미를 발견하고, 수치 해석을 통해 문제 해결이 가능하다는 점에서 매력적인 직무에요.`,
  },
  mobile: {
    title: '앱 개발자',
    image: MOBILE,
    description: `🤔 앱 개발자가 뭐에요?

    앱 개발자는 스마트폰을 포함한 다양한 모바일 기기에서 사용되는 모바일 앱을 개발하는 직무를 의미해요. 앱 개발자는 프론트엔드 업무 중 모바일 앱 영역에 초점이 맞춰진 업무를 해요. 모바일 앱 화면부터 기능 구현까지 사용자가 편리하게 모바일를 사용할 수 있도록 개발하죠.
     앱은 운영체제에 따라 iOS와 Android로 나뉘어져요. 시스템 구조가 달라 서로 다른 툴을 이용하여 개발됩니다. iOS 개발자는 애플의 가이드라인 안에서 앱을 구현해야한다는 특징이 있고, Android 개발자는 자유롭게 다양한 기기에 맞게 개발할 수 있다는 특징이 있어요.
    
    🤔 어떤 사람이 앱 개발에 어울릴까요?
    
    자신의 아이디어를 완성된 형태로 세상에 선보이는 걸 좋아하는 분에게 어울리는 직무에요. 서비스 기획부터 앱 구성(UI)에 대한 이해가 있다면 스스로 개발부터 서비스까지 가능하다는 장점이 있어요. 자신의 아이디어가 뚜렷하다면 비교적 빠르게 결과물을 만들 수 있다는 점에서 매력적인 직무에요.  `,
  },
};
