const PORT = 443;
const IP_ADDRESS = 'api.devroute.site';

//카카오 sso client ID
const KAKAO_CLIENT_ID = '0152841242e517dee9373507d1613109';

//구글 sso client ID
const GOOGLE_CLIENT_ID =
  '1084301420381-atfk08r5seqjk97dcbc5gkogt6h2fjbk.apps.googleusercontent.com';

//네이버 sso client ID
const NAVER_CLIENT_ID = 'h0YVkRRmuK9LHvfFephw';
const NAVER_CLIENT_SECRET = 'NpxHYwRMxd';

const GITHUB_LINK = 'www.naver.com';
const NOTION_LINK = 'www.google.com';

export {
  PORT,
  IP_ADDRESS,
  KAKAO_CLIENT_ID,
  GOOGLE_CLIENT_ID,
  NAVER_CLIENT_ID,
  NAVER_CLIENT_SECRET,
  GITHUB_LINK,
  NOTION_LINK,
};
